import React from 'react';

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        {/* <li><a href="https://twitter.com/khorovatin" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li> */}
        <li>
          <a
            href="https://www.facebook.com/HorovatinConsultingLtd"
            className="icon alt fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        {/* <li><a href="https://www.instagram.com/khorovatin/" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li> */}
        {/* <li><a href="https://github.com/khorovatin" className="icon alt fa-github"><span className="label">GitHub</span></a></li> */}
        <li>
          <a
            href="https://www.linkedin.com/company/horovatin-consulting-ltd"
            className="icon alt fa-linkedin"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; Horovatin Consulting Ltd.</li>
        <li>
          Design: <a href="https://html5up.net">HTML5 UP</a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
